html {
  min-width: 350px;
  height: 100vh;
  margin: 0;
  padding: 0;
}

main {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
  transition-delay: 500ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 1s ease-out;
  transition-delay: 500ms;
}

p.react-viewer-attribute {
  color: white;
  font-size: 25px;
}

div.react-viewer-canvas {
  background-color: #0009;
}
